import BarLoader from 'react-spinners/BarLoader'
import MoonLoader from 'react-spinners/MoonLoader'
import PropagateLoader from 'react-spinners/PropagateLoader'
import ClipLoader from 'react-spinners/ClipLoader'
import { CCard, CCardBody } from '@coreui/react-pro'

interface BarLoaderProps {
  label?: string
  width?: string
}

export const LoadingSpinner = ({ label, width = '100%' }: BarLoaderProps) => (
  <div className="body-loading-spinner d-flex">
    {label && <h4 className="mb-3">{label}</h4>}
    <BarLoader color="#3399ff" width={width} height={'4px'} />
  </div>
)

interface MoonLoaderProps {
  label?: string
  size?: string
}

export const FallbackLoading = ({ label, size = '28px' }: MoonLoaderProps) => (
  <div className={`center-screen`} style={{ margin: '0 auto' }}>
    {label && <h4 className="mb-3">{label}</h4>}
    <MoonLoader color="#3399ff" size={size} />
  </div>
)

export const TableLoadingSpinner = ({ label, size = '15' }: MoonLoaderProps) => (
  <div className="">
    {label && <h4 className="mb-3">{label}</h4>}
    <PropagateLoader color="#3399ff" size={size} />
  </div>
)

export const InlineSpinner = ({ size = '20px' }: MoonLoaderProps) => (
  <ClipLoader color="#3399ff" size={size} />
)

export const ContentLoader = ({ size = '30px' }: MoonLoaderProps) => (
  <ClipLoader color="#3399ff" size={size} />
)

export const ContentCardLoader = ({ size = '30px' }: MoonLoaderProps) => (
  <CCard>
    <CCardBody className="d-flex align-items-center justify-content-center py-5">
      <ContentLoader />
    </CCardBody>
  </CCard>
)

export const ButtonLoader = ({ size = '20px' }: MoonLoaderProps) => (
  <ClipLoader color="white" size={size} />
)
