export const ROUTES = {
  home: '/',
  login: '/login',
  dashboard: '/dashboard',

  users: '/users',
  userDetail: '/users/:id',

  agencies: '/agencies',
  agencyDetail: '/agencies/:id',

  events: '/events',
  eventDetail: '/events/:id',
  eventOrderDetail: '/events/orders/:id',

  customers: '/customers',
  customerDetail: '/customers/:id',

  transactions: '/transactions',
  transactionDetail: '/transactions/:id',

  contracts: '/contracts',
  contractDetail: '/contracts/:id',

  //web
  viewWebEvent: '/web/events/:slug',
  viewWebOrder: '/web/orders/:orderId',

  viewWebCanceled: '/web/cancelled',
  viewWeb: '/web',
}
