import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './i18n/config'
import './polyfill'
import ReactDOM from 'react-dom'
import { Toaster } from 'react-hot-toast'
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

const queryClient = new QueryClient()

ReactDOM.render(
  <>
    <Toaster position="top-center" />
    <IntlProvider locale="en">
      <QueryClientProvider client={queryClient}>
        <Router>
          <App />
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </IntlProvider>
  </>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
