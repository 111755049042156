import { lazy, Suspense } from 'react'

import { Route, Routes } from 'react-router-dom'
import { FallbackLoading } from './components/LoadingSpinner'

import './scss/style.scss'
import 'moment/locale/fr'

import { ROUTES } from 'layout/routes'

const Page404 = lazy(() => import('./views/pages/Page404'))
const Page500 = lazy(() => import('./views/pages/Page500'))

const DefaultLayout = lazy(() => import('./layout/TheLayout'))
const Login = lazy(() => import('./views/auth/Login'))
const ViewWebEvent = lazy(() => import('./views/web/ViewWebEvent'))
const ViewWebOrder = lazy(() => import('./views/web/ViewWebOrder'))

const ViewWeb = lazy(() => import('./views/web/ViewWeb'))
const ViewWebCanceled = lazy(() => import('./views/web/ViewWebCanceled'))

const App = () => {
  return (
    <Suspense fallback={<FallbackLoading />}>
      <Routes>
        <Route path={ROUTES.login} element={<Login />}></Route>
        <Route path={ROUTES.viewWebEvent} element={<ViewWebEvent />}></Route>
        <Route path={ROUTES.viewWebOrder} element={<ViewWebOrder />}></Route>
        <Route path={ROUTES.viewWeb} element={<ViewWeb />}></Route>
        <Route path={ROUTES.viewWebCanceled} element={<ViewWebCanceled />}></Route>
        <Route path="/404" element={<Page404 />}></Route>
        <Route path="/500" element={<Page500 />}></Route>
        <Route path="*" element={<DefaultLayout />}></Route>
      </Routes>
    </Suspense>
  )
}
export default App
