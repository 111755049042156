import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const enJson = require('./en/translation.json')
const frJson = require('./fr/translation.json')

const i18nextOptions: any = {
  //debug: true,
  interpolation: {
    escapeValue: false,
  },
  //saveMissing: true,
  lng: 'fr',
  fallbackLng: 'fr',
  whitelist: ['en', 'fr'],
  react: {
    //wait: false,
    useSuspense: false,
  },
  resources: {
    en: {
      translation: enJson,
    },
    fr: {
      translation: frJson,
    },
  },
}

i18next.use(LanguageDetector).use(initReactI18next).init(i18nextOptions)
